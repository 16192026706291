/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import styles from './Arrears.module.scss';
import { IGetRepayStatusResponse, IOpenLoanDetailsResponse } from '../../../../clients/LoanClient';
import { IGetPersonalDetailsResponse } from '../../../../clients/AccountClient';
import { useNavigate } from 'react-router';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { RouterRoutes } from '../../../../utils/RouterRoutes';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { formatMoney } from '../../../../utils/Helpers/SliderHelperFunctions';
import RepayNoticeModal from '../../../../components/RepayNoticeModal/RepayNoticeModal';
import { formatDateWithDefault } from '../../../../utils/Helpers/DateHelpers';

interface Arrears {
  openLoanResponse: IOpenLoanDetailsResponse;
  personalDetails: IGetPersonalDetailsResponse;
  repayStatus: IGetRepayStatusResponse | null;
}

const Arrears: React.FunctionComponent<Arrears> = ({ openLoanResponse, personalDetails, repayStatus }: Arrears) => {
  const { collectionDates, term, loanAmount, termInDays, totalServiceFees, interestAmount } =
    openLoanResponse.quotationData || {};
  const { currentBalance, collectionDate, totalArrearsAmount, collectionInstalment } = openLoanResponse.loanData || {};
  const { firstName } = personalDetails.personalDetails?.customer || {};
  const navigate = useNavigate();
  const [showRepayModal, setShowRepayModal] = useState<boolean>(false);
  const isInstallmentLoan = collectionDates?.length! > 0;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const repayEnabled = repayStatus?.enabled === true;

  const calculateIlOrSTL = () => {
    if (isInstallmentLoan) {
      return <>{term} Months</>;
    } else {
      return <>{termInDays} Days</>;
    }
  };

  const navigateToRepay = () => {
    navigate(`${RouterRoutes.repay}`);
  };

  const getAmountOwed = (): string => {
    if (totalArrearsAmount) return formatMoney(totalArrearsAmount);
    return formatMoney(currentBalance ?? 0);
  };

  return (
    <>
      {showRepayModal && <RepayNoticeModal showModal={showRepayModal} onClose={navigateToRepay} />}
      <div className={styles['arrears-container']}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
          fontWeight={400}
          lineHeight={'3.2rem'}
          letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
          padding={isMobile ? '2rem 1rem 0rem 0rem' : '0rem 0rem 0rem 1rem'}
        >
          Hi {firstName}
        </Typography>

        <Stack marginTop={isMobile ? '1.6rem' : '2.8rem'} padding={isMobile ? '0rem' : '0rem 1rem'}>
          <Typography fontSize="1.5rem" fontWeight={300} lineHeight={'2rem'}>
            Your instalment was&nbsp;
            <Typography component="span" fontSize="1.5rem" fontWeight={600} lineHeight={'2rem'}>
              declined
            </Typography>
            &nbsp;by your bank and is now&nbsp;
            <Typography component="span" fontSize="1.5rem" fontWeight={600} lineHeight={'2rem'}>
              overdue.&nbsp;
            </Typography>
            <Typography
              component={'span'}
              fontSize="1.5rem"
              fontWeight={300}
              lineHeight={'2rem'}
              display={isMobile ? 'block' : 'inline'}
              marginY={isMobile ? '1.6rem' : '0rem'}
            >
              You owe&nbsp;{' '}
              <Typography component={'span'} fontSize="1.5rem" fontWeight={600} lineHeight={'2rem'}>
                R {getAmountOwed()}
              </Typography>
              &nbsp;today.
            </Typography>
          </Typography>
          <br />
          <Typography fontWeight={300} letterSpacing={'-0.7px'}>
            You are in default and interest is continuing to accrue on the outstanding amount.
          </Typography>
        </Stack>

        <Typography
          variant="body1"
          fontSize={'3.2rem'}
          fontWeight={600}
          lineHeight={'2.2rem'}
          letterSpacing={'-0.04em'}
          textAlign={'center'}
          marginTop={isMobile ? '2.4rem' : '4rem'}
          color={theme.palette.primary.dark}
        >
          R {formatMoney(currentBalance ?? 0)}
        </Typography>
        <Stack
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          marginTop={'1.1rem'}
          marginBottom={'2rem'}
        >
          <Typography variant="body1" fontSize={'1.4rem'} fontWeight={400} lineHeight={'1.7rem'}>
            Settlement balance
          </Typography>
          <Typography variant="body1" fontSize={'1.4rem'} fontWeight={300} lineHeight={'1.7rem'}>
            {`${formatDateWithDefault(undefined, 'dd MMM yyyy')}`}
          </Typography>
        </Stack>

        <div className={styles['arrears-table']}>
          <div>Repayment Amount</div>
          <div>R {formatMoney(collectionInstalment ?? 0)}</div>
        </div>
        <div className={`${styles['arrears-table']} ${styles['border-bottom']}`}>
          <div>Debit Date</div>
          <div>{formatDateWithDefault(collectionDate, 'do MMM yyyy')}</div>
        </div>
        <br />
        <div className={styles['arrears-table-bold']}>Loan Details</div>
        <div className={styles['arrears-table']}>
          <div>Loan Amount</div>
          <div>R {formatMoney(loanAmount ?? 0)}</div>
        </div>
        <div className={styles['arrears-table']}>
          <div>Interest</div>
          <div>R {formatMoney(interestAmount ?? 0)}</div>
        </div>
        <div className={styles['arrears-table']}>
          <div>Total Fees</div>
          <div>R {formatMoney(totalServiceFees ?? 0)}</div>
        </div>
        <div className={`${styles['arrears-table']} ${styles['border-bottom']}`}>
          <div> Term</div>
          <div>{calculateIlOrSTL()}</div>
        </div>
        {repayEnabled && (
          <Stack
            display={'flex'}
            flexDirection={'column'}
            alignItems={{ xs: 'center', sm: 'flex-end' }}
            gap={'1.2rem'}
            sx={{ margin: { xs: '1.3rem 0rem 2rem 0rem', sm: '1.9rem 0rem 2rem 0rem' } }}
          >
            <Typography variant="body1" fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
              <Typography component="span" variant="body1" fontSize={'1.5rem'} fontWeight={500} lineHeight={'2.2rem'}>
                Settle
              </Typography>{' '}
              or{' '}
              <Typography component="span" variant="body1" fontSize={'1.5rem'} fontWeight={500} lineHeight={'2.2rem'}>
                repay any amount
              </Typography>{' '}
              towards your loan
            </Typography>
            <PrimaryButton onClick={() => setShowRepayModal(true)}>Make payment</PrimaryButton>
          </Stack>
        )}
      </div>
    </>
  );
};

export default Arrears;
