import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { FULL_COLUMN_SIZE, THIRD_COLUMN_SIZE, TWO_THIRDS_COLUMN_SIZE } from '../../../utils/GridColumnSizeDefinitions';
import {
  Captcha,
  ConfirmMobileVerificationRequest,
  ConfirmMobileVerificationResponse,
  IConfirmMobileVerificationRequest,
  IContactDetails,
  IMobileVerificationRequest,
  MobileVerificationRequest,
  MobileVerificationResponse,
} from '../../../clients/AccountClient';
import { Form, useForm } from '../../../components/Form/Form';
import FormInputWrapper from '../../../components/Form/FormInputWrapper';
import RequestOtp from '../../../components/Form/Forms/RequestOtp';
import VerifyOtp from '../../../components/Form/Forms/VerifyOtp';
import FormWrapper from '../../../components/Form/FormWrapper';
import Loading from '../../../components/Loading/Loading';
import { cellphoneSchema, OTPSchema } from '../../../schemas/Schemas';
import FormInputLabel from '../../../components/Form/FormInputLabel';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import useAccountClient from '../../../hooks/account/Client';
import { MobileVerificationData } from '../../../components/Otp/Interfaces';
import InputFormController from '../../../components/MuiInput/FormControllers/InputFormController';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import ChangeLoanValues from '../../../components/ChangeLoanValues/ChangeLoanValues';
import { useAppSettings } from '../../../contexts/AppSettingsContext';
import { useWizardOutletContext } from '../Wizard';
import { TurnstileInstance } from '@marsidev/react-turnstile';
import { useTracking } from '../../../Tracking/TrackingContext';
import TurnstileSize from '../../../utils/Turnstile';

/* eslint-disable  @typescript-eslint/no-unused-vars*/
const OtpPage: React.FunctionComponent = () => {
  const client = useAccountClient();
  const [contact, setContact] = useState<IContactDetails>();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
  const [editing, setIsEditing] = useState(false);
  const [otpRequested, setOtpRequested] = useState<boolean>(false);
  const [otpSubmitted, setOtpSubmitted] = useState<boolean>(false);
  // const { next } = useJourney();
  const { next } = useWizardOutletContext();
  const { displaySnackBar } = useSnackBar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isMobileNumberFieldDisabled, setMobileNumberFieldDisabled] = useState<boolean>(false);
  const mobileNumberForm = useForm({
    criteriaMode: 'all',
    mode: 'onChange',
    schema: cellphoneSchema,
  });

  const otpForm = useForm({
    criteriaMode: 'all',
    mode: 'onChange',
    schema: OTPSchema,
  });

  const turnstileWidgetRef = useRef<TurnstileInstance>(null);
  const appSettings = useAppSettings();
  const cfTurnstileDisabled = appSettings.cfTurnstileConfig.cfTurnstileDisabled;
  const { TrackError } = useTracking();
  const [turnstileSize, setTurnstileSize] = useState<TurnstileSize>('invisible');
  const [requestOtpTurnstileToken, setRequestOtpTurnstileToken] = useState<string | null>(null);
  const [verifyOtpTurnstileToken, setVerifyOtpTurnstileToken] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      const response = await client.getContact();
      if (response) {
        setContact(response.contact);
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        mobileNumberForm.setValue('cellphoneNumber', response.contact?.mobileNumber!);
        const mobileNumberExists = response.contact?.mobileNumber != null;
        if (mobileNumberExists) setMobileNumberFieldDisabled(true);
        setIsEditing(response.contact?.mobileNumber === '');
        setIsFetchingData(false);
      }
    } catch (error) {
      displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const requestMobileVerification = async (data: MobileVerificationData) => {
    setSubmitLoading(true);

    setContact((prev) => {
      return {
        ...prev,
        mobileNumber: data.cellphoneNumber,
      };
    });

    mobileNumberForm.setValue('cellphoneNumber', data.cellphoneNumber);

    try {
      const request: IMobileVerificationRequest = {
        mobileNumber: data.cellphoneNumber,
      };

      const captcha = new Captcha();
      captcha.token = requestOtpTurnstileToken || undefined;
      captcha.providerName = appSettings.captchaProvider;
      request.captcha = captcha;

      const response: MobileVerificationResponse = await client.requestMobileVerification(
        new MobileVerificationRequest(request)
      );

      if (!cfTurnstileDisabled && !response.captchaSuccess) {
        if (turnstileWidgetRef.current) turnstileWidgetRef.current.reset();
        TrackError(`RequestOTP: Failed due to unsuccessful CAPTCHA response`);
        return;
      }

      if (response.success) {
        setTurnstileSize('invisible');
        setOtpRequested(true);
        displaySnackBar("OTP on it's way", 'success');
        otpForm.setValue('cellphoneNumber', data.cellphoneNumber);
      }
    } catch (error) {
      console.log(error);
      displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
    } finally {
      setSubmitLoading(false);
    }
  };

  const confirmMobileVerfification = async (data: ConfirmMobileVerificationData) => {
    setSubmitLoading(true);

    try {
      const request: IConfirmMobileVerificationRequest = {
        pin: data.otp,
      };

      const captcha = new Captcha();
      captcha.token = verifyOtpTurnstileToken || undefined;
      captcha.providerName = appSettings.captchaProvider;
      request.captcha = captcha;

      const response: ConfirmMobileVerificationResponse = await client.confirmMobileVerification(
        new ConfirmMobileVerificationRequest(request)
      );

      if (!cfTurnstileDisabled && !response.captchaSuccess) {
        if (turnstileWidgetRef.current) turnstileWidgetRef.current.reset();
        TrackError(`ConfirmOTP: Failed due to unsuccessful CAPTCHA response`);
        return;
      }

      if (response.success) {
        setOtpSubmitted(true);
        setIsEditing(false);
        setTurnstileSize('invisible');
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        displaySnackBar(response.message!, 'success');
        next();
      } else {
        displaySnackBar(response.message!, 'error');
      }
    } catch (error) {
      console.log(error);
      displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
    } finally {
      setSubmitLoading(false);
    }
  };

  const resendMobileVerification = async () => {
    setSubmitLoading(true);

    const mobileNumber = mobileNumberForm.getValues().cellphoneNumber;
    setContact((prev) => {
      return {
        ...prev,
        mobileNumber: mobileNumber,
      };
    });

    mobileNumberForm.setValue('cellphoneNumber', mobileNumber);

    try {
      const request: IMobileVerificationRequest = {
        mobileNumber: mobileNumber,
      };

      const captcha = new Captcha();
      captcha.token = verifyOtpTurnstileToken || undefined;
      captcha.providerName = appSettings.captchaProvider;
      request.captcha = captcha;

      const response: MobileVerificationResponse = await client.resendMobileVerification(
        new MobileVerificationRequest(request)
      );

      if (!cfTurnstileDisabled && !response.captchaSuccess) {
        if (turnstileWidgetRef.current) turnstileWidgetRef.current.reset();
        TrackError(`ResendOTP: Failed due to unsuccessful CAPTCHA response`);
        return;
      }

      if (response.success) {
        setTurnstileSize('invisible');
        setOtpRequested(true);
        displaySnackBar('OTP resent', 'success');
      }
    } catch (error) {
      console.log(error);
      displaySnackBar('Oops, an error has occurred please try again', 'error');
    } finally {
      setSubmitLoading(false);
    }
  };

  const ChangeMobileNumberOnClickHandler = () => {
    setMobileNumberFieldDisabled(false);
    setOtpRequested(false);
    setOtpSubmitted(false);
    setIsEditing(true);
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    mobileNumberForm.setValue('cellphoneNumber', contact?.mobileNumber!);
    otpForm.reset();
  };

  if (isFetchingData) return <Loading text="Please wait while we retrieve your details." />;

  const displayFormContent = (
    <>
      <Form form={mobileNumberForm} onSubmit={() => null}>
        <Grid container>
          <FormInputLabel>Cell phone number</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="cellphoneNumber"
              label="Cell phone number"
              placeholder="Cell phone number"
              register={mobileNumberForm.register}
              control={mobileNumberForm.control}
              disabled={isMobileNumberFieldDisabled}
            />
          </FormInputWrapper>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={FULL_COLUMN_SIZE} sm={THIRD_COLUMN_SIZE}></Grid>
          <Grid item xs={FULL_COLUMN_SIZE} sm={TWO_THIRDS_COLUMN_SIZE}>
            <Button fullWidth={isMobile} variant="text" type="submit" onClick={ChangeMobileNumberOnClickHandler}>
              Change mobile number
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );

  return (
    <>
      <ChangeLoanValues />
      <FormWrapper title="My Details">
        {!editing && contact?.mobileNumber && displayFormContent}
        {editing && !otpSubmitted && !otpRequested && (
          <RequestOtp
            isMobile={isMobile}
            mobileNumberForm={mobileNumberForm}
            requestOtp={requestMobileVerification}
            submitLoading={submitLoading}
            turnstileWidgetRef={turnstileWidgetRef}
            cfTurnstileToken={requestOtpTurnstileToken}
            turnstileSize={turnstileSize}
            setTurnstileSize={setTurnstileSize}
            setToken={setRequestOtpTurnstileToken}
          />
        )}

        {editing && otpRequested && !otpSubmitted && (
          <VerifyOtp
            isMobile={isMobile}
            mobileNumberForm={mobileNumberForm}
            otpForm={otpForm}
            verifyOtp={confirmMobileVerfification}
            resendOtp={resendMobileVerification}
            submitLoading={submitLoading}
            turnstileWidgetRef={turnstileWidgetRef}
            cfTurnstileToken={verifyOtpTurnstileToken}
            turnstileSize={turnstileSize}
            setTurnstileSize={setTurnstileSize}
            setToken={setVerifyOtpTurnstileToken}
          />
        )}
      </FormWrapper>

      {!editing && (
        <Container sx={{ padding: '0 1.6rem 5rem 1.6rem' }}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={FULL_COLUMN_SIZE}
              display="flex"
              justifyContent={{
                xs: 'flex-end',
              }}
            >
              <PrimaryButton type="submit" disabled={!contact?.mobileNumber} onClick={() => next()}>
                Next
              </PrimaryButton>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default OtpPage;
export interface ConfirmMobileVerificationData {
  otp: string;
}
