import CloseIcon from '@mui/icons-material/Close';
import { Snackbar, SnackbarCloseReason, SnackbarContent, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import errorLogo from '../assets/img/svg/Error_Circle.svg';
import successLogo from '../assets/img/svg/Tick_Circle.svg';
import noteLogo from '../assets/img/svg/note_circle.svg';

export type SnackBarVariant = 'success' | 'error' | 'note';

export const SnackBarContext = React.createContext({
  /* eslint-disable  @typescript-eslint/no-unused-vars*/
  displaySnackBar: (
    message: string,
    variant: SnackBarVariant,
    hideTitle?: boolean | undefined,
    autoHide?: boolean | undefined
  ) => {
    /* do nothing */
  },
  displaySnackBarSpecial: (
    component: React.ReactNode,
    variant: SnackBarVariant,
    hideTitle?: boolean | undefined,
    autoHide?: boolean | undefined
  ) => {
    /* do nothing */
  },
});

export const useSnackBar = () => {
  return useContext(SnackBarContext);
};

interface snackBarProviderProps {
  children: React.ReactNode;
}

const SnackBarProvider = (props: snackBarProviderProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [component, setComponent] = useState<React.ReactNode | undefined>();
  const [variant, setVariant] = useState<SnackBarVariant>('success');
  const [hideTitle, setHideTitle] = useState<boolean>(false);
  const [autoHide, setAutoHide] = useState<boolean>(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const commonStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',

    ...(isMobile ? {} : { width: '375px' }),
  };

  const colorPalette = {
    error: '#D0021B',
    info: '#7AC206',
    note: '#F7902F',
    success: '#7AC206',
  };

  const variantStyles: { [key in SnackBarVariant]: React.CSSProperties } = {
    error: {
      ...commonStyles,
      backgroundColor: '#ECBEC3',
      color: 'black',
      borderLeft: `10px solid ${colorPalette.error}`,
    },
    note: {
      ...commonStyles,
      backgroundColor: '#FDE3CB',
      color: 'black',
      borderLeft: `10px solid ${colorPalette.note}`,
    },
    success: {
      ...commonStyles,
      backgroundColor: '#E0F5D6',
      color: 'black',
      borderLeft: `10px solid ${colorPalette.success}`,
    },
  };

  const handleClose = (
    /* eslint-disable  @typescript-eslint/no-explicit-any*/
    event: Event | React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };

  const content = (
    <Snackbar
      anchorOrigin={{
        vertical: isMobile ? 'bottom' : 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={autoHide ? 3000 : null}
      onClose={handleClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <SnackbarContent
        style={{ ...variantStyles[variant] }}
        message={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
              <img
                src={
                  variant === 'success'
                    ? successLogo
                    : variant === 'error'
                    ? errorLogo
                    : variant === 'note'
                    ? noteLogo
                    : successLogo
                }
                alt={variant}
                style={{ width: 21 }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {!hideTitle && (
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  {variant === 'success'
                    ? 'Success'
                    : variant === 'error'
                    ? 'Error'
                    : variant === 'note'
                    ? 'Note'
                    : variant}
                </Typography>
              )}
              {component !== undefined ? component : <Typography variant="body1">{message}</Typography>}
            </div>
            <CloseIcon
              onClick={() => setOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: colorPalette[variant],
                fontSize: '21px',
                cursor: 'pointer',
              }}
            />
          </div>
        }
      />
    </Snackbar>
  );

  const displaySnackBar = useCallback(
    (message: string, variant: SnackBarVariant, hideTitle?: boolean | undefined, autoHide?: boolean | undefined) => {
      setMessage(message);
      setVariant(variant);
      setHideTitle(hideTitle ?? false);
      setAutoHide(autoHide ?? true);
      setComponent(undefined);
      setOpen(true);
    },
    []
  );

  const displaySnackBarSpecial = useCallback(
    (
      component: React.ReactNode,
      variant: SnackBarVariant,
      hideTitle?: boolean | undefined,
      autoHide?: boolean | undefined
    ) => {
      setComponent(component);
      setVariant(variant);
      setHideTitle(hideTitle ?? false);
      setAutoHide(autoHide ?? true);
      setOpen(true);
    },
    []
  );

  const values = {
    displaySnackBar,
    displaySnackBarSpecial,
  };

  return (
    <>
      <SnackBarContext.Provider value={values}>{props.children}</SnackBarContext.Provider>
      {ReactDOM.createPortal(
        content,
        /* eslint-disable  @typescript-eslint/no-non-null-assertion*/
        document.getElementById('snackbar-root')!
      )}
    </>
  );
};

export default SnackBarProvider;
