import { Stack, Typography } from '@mui/material';
import Loading from '../../../../components/Loading/Loading';
import React, { useCallback, useEffect, useState } from 'react';
import { RouterRoutes } from '../../../../utils/RouterRoutes';
import useLoanClient from '../../../../hooks/loan/Client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTracking } from '../../../../Tracking/TrackingContext';
import {
  navigateToFailedOpenBanking,
} from '../../../../utils/Helpers/NavigationHelper';
import { IOpenLoanDetailsResponse } from '../../../../clients/LoanClient';

export const OpenBankingProcessing = () => {
  const location = useLocation();
  const loanId = location && location.state ? location.state["loanId"] ?? "" : "";
  const loanClient = useLoanClient();
  const navigate = useNavigate();
  const { isExistingCustomer, TrackError } = useTracking();
  const [openBankingCompleted, setOpenBankingCompleted] = useState(false);

  const getLoanDetails = useCallback(async () => {
    return await loanClient.getOpenLoanDetails();
  }, [loanClient]);

  const waitForOpenBankingTransactionsProcessing = useCallback(async () => {
    if (!loanId){
      throw new Error("The LoanId is null")
    }

    let loanDetails: IOpenLoanDetailsResponse | null = null;

    try {
      loanDetails = await getLoanDetails();

      while (!openBankingCompleted){
        // We expect mandatePending = true as the next state after the customer income is verified
        const loanState = await loanClient.waitApplication(
          loanId,
          30,
          false,
          false,
          true,
          false,
          false,
          false
        );

        const openBankingDone = loanState.avaInfo?.openBankingCompleted ?? false;
        setOpenBankingCompleted(openBankingDone);

        if (openBankingDone){
          if (loanState.applicationAdvice?.requireDocuments){
            navigateToFailedOpenBanking(
              navigate,
              loanId,
              isExistingCustomer,
              loanDetails?.quotationData?.loanAmount ?? 0,
              "Oops, we couldn't verify your income.")
          }else{
            navigate(`/${RouterRoutes.myLoan}`);
          }
          break;
        }
      }
    } catch (error) {
      TrackError(error);
    }
  }, [loanId, getLoanDetails, openBankingCompleted, loanClient, navigate, isExistingCustomer, TrackError]);

  useEffect(() => {
    waitForOpenBankingTransactionsProcessing();
  }, [getLoanDetails, waitForOpenBankingTransactionsProcessing]);

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
        alignItems="center" paddingTop={4} paddingBottom={4}
      >

        <Typography variant="h2" paddingBottom={2}>One moment</Typography>
        <Loading />
        <Typography variant="body1" paddingTop={2} textAlign={'center'}>
          We are processing your application, please do not close your browser.
        </Typography>
      </Stack>
    </>
  )
}
