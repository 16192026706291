/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styles from './AccountTimeline.module.scss';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { IOpenLoanDetailsResponse } from '../../../../clients/LoanClient';
import { Avatar, Box, Link, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import InformationIcon from '../../../../assets/img/webp/information-2x.webp';
import { theme } from '../../../../theme/Theme';
import CloseIcon from '../../../../assets/img/Close.png';

type AccountTimelineProps = {
  loanStatus: number;
  openLoanResponse: IOpenLoanDetailsResponse;
};

enum LOAN_STATUS_ENUM {
  PENDING_INCOME_AND_BANK_VERIFICATION = 3,
  PENDING_MANDATE_ACCEPTANCE = 4,
  DISBURSING = 5,
}

const AccountTimeline: React.FunctionComponent<AccountTimelineProps> = ({ loanStatus, openLoanResponse }) => {
  const { loanAmount, term, applicationDate, termInDays } = openLoanResponse.quotationData || {};
  const [showDebiCheckInfo, setShowDebiCheckInfo] = useState(false);
  const openModalWindow = () => {
    setShowDebiCheckInfo(!showDebiCheckInfo);
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return format(date, 'do MMMM yyyy');
  };

  const getCurrentStep = (loanStatus: number) => {
    if (loanStatus === LOAN_STATUS_ENUM.PENDING_INCOME_AND_BANK_VERIFICATION) return 1;

    return loanStatus - 1;
  };

  // Note: The indexes of these steps don't directly map to the OpenLoanStatus enum.
  const steps = [
    <Typography key={1} fontSize={'1.8rem'} fontWeight={500} lineHeight={'2.1rem'} letterSpacing={'-0.1rem'}>
      Loan agreement signed
    </Typography>,
    <Stack key={2} display={'flex'} flexDirection={'column'}>
      <Typography fontSize={'1.8rem'} fontWeight={500} lineHeight={'2.1rem'} letterSpacing={'-0.1rem'}>
        Income & bank verified
      </Typography>
      {loanStatus === LOAN_STATUS_ENUM.PENDING_INCOME_AND_BANK_VERIFICATION && (
        <Typography
          fontSize={'1.2rem'}
          fontWeight={500}
          lineHeight={'2.1rem'}
          letterSpacing={'-0.1rem'}
          color={theme.palette.grey[500]}
        >
          Processing, this may take{' '}
          <Typography
            component={'span'}
            fontSize={'1.2rem'}
            fontWeight={700}
            lineHeight={'2.1rem'}
            letterSpacing={'-0.1rem'}
            color={theme.palette.grey[500]}
          >
            a few hours...
          </Typography>
        </Typography>
      )}
    </Stack>,
    <Box key={3} display={'inline-flex'}>
      <Typography
        fontSize={'1.8rem'}
        fontWeight={500}
        lineHeight={'2.1rem'}
        letterSpacing={'-0.1rem'}
        sx={{ paddingRight: '0.8rem' }}
      >
        DebiCheck mandate request sent
      </Typography>
      <span onClick={openModalWindow} className={styles['info-icon']}></span>
    </Box>,
    <Stack key={2} display={'flex'} flexDirection={'column'}>
      <Typography key={4} fontSize={'1.8rem'} fontWeight={500} lineHeight={'2.1rem'} letterSpacing={'-0.1rem'}>
        DebiCheck mandate approved
      </Typography>
      {loanStatus === LOAN_STATUS_ENUM.PENDING_MANDATE_ACCEPTANCE && (
        <Typography
          fontSize={'1.2rem'}
          fontWeight={500}
          lineHeight={'2.1rem'}
          letterSpacing={'-0.1rem'}
          color={theme.palette.grey[500]}
        >
          Pending your approval
        </Typography>
      )}
    </Stack>,
    <Typography key={5} fontSize={'1.8rem'} fontWeight={500} lineHeight={'2.1rem'} letterSpacing={'-0.1rem'}>
      Cash is on its way
    </Typography>,
  ];

  return (
    <div className={styles['timeline']}>
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={400}
        lineHeight={'3.2rem'}
        letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
        marginBottom={{ xs: '2.2rem', md: '1.8rem' }}
      >
        {loanStatus === LOAN_STATUS_ENUM.PENDING_MANDATE_ACCEPTANCE
          ? 'Verification complete!'
          : 'Success! Leave the rest to us.'}
      </Typography>
      <Typography
        fontSize={'1.5rem'}
        fontWeight={300}
        lineHeight={'2.1rem'}
        letterSpacing={'-0.05rem'}
        sx={{ marginBottom: { xs: '2.2rem', md: '2.4rem' } }}
      >
        {loanStatus === LOAN_STATUS_ENUM.PENDING_MANDATE_ACCEPTANCE
          ? 'Great, your income and bank details have been verified. Please approve your DebiCheck mandate.'
          : 'We are currently reviewing your income & bank account details. We will get back to you if there is anything we still need.'}
      </Typography>
      {loanStatus === LOAN_STATUS_ENUM.PENDING_INCOME_AND_BANK_VERIFICATION && (
        <div className={styles['information-container']}>
          <img src={InformationIcon} alt="Info icon" />
          <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.1rem'} letterSpacing={'-0.05rem'}>
            This process can take a{' '}
            <Typography
              component={'span'}
              fontSize={'1.5rem'}
              fontWeight={700}
              lineHeight={'2.1rem'}
              letterSpacing={'-0.05rem'}
            >
              few hours.
            </Typography>{' '}
            Please be patient.
          </Typography>
        </div>
      )}
      <Box className={styles['stepper-container']} sx={{ padding: { xs: '20px 20px', md: '0px 0px' } }}>
        <Stepper orientation="vertical" activeStep={getCurrentStep(loanStatus)}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={styles['last-divider']}>
          <span></span>
        </div>

        {/*
         * DebiCheckPopup
         * We need adjust the alignment properties based on the loan status
         * as content is conditionally rendered based on it
         */}
        <Box
          display={showDebiCheckInfo ? 'block' : 'none'}
          position={'absolute'}
          sx={{
            top: {
              xs: loanStatus === LOAN_STATUS_ENUM.PENDING_MANDATE_ACCEPTANCE ? '240px' : '340px',
              md: 'unset',
            },
            bottom: {
              xs: 'unset',
              md: loanStatus === LOAN_STATUS_ENUM.PENDING_MANDATE_ACCEPTANCE ? '180px' : '165px',
            },
            left: { xs: '0', md: '0' },
            right: { xs: '0', md: '0' },
            margin: {
              xs: '0 auto',
              md: 'unset',
            },
            maxWidth: '35.7rem',
            borderRadius: '0.5rem',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
            transition: 'all 0.25s ease-in-out',
            zIndex: '1',
          }}
        >
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexGrow={1}
            sx={{
              padding: '0.4rem 1.3rem',
              backgroundColor: theme.palette.secondary.main,
              borderTopLeftRadius: '0.5rem',
              borderTopRightRadius: '0.5rem',
            }}
          >
            <Typography
              fontSize={'1.8rem'}
              fontWeight={500}
              lineHeight={'4rem'}
              color={theme.palette.common.white}
              fontStyle={'normal'}
            >
              What is a DebiCheck?
            </Typography>
            <Avatar
              src={CloseIcon}
              alt={'Close Icon'}
              sx={{ width: '2.5rem', height: '3.2rem', cursor: 'pointer' }}
              onClick={() => setShowDebiCheckInfo(!showDebiCheckInfo)}
            />
          </Stack>

          <Box
            sx={{
              backgroundColor: theme.palette.common.white,
              padding: '2rem',
              borderBottomLeftRadius: '0.5rem',
              borderBottomRightRadius: '0.5rem',
            }}
          >
            <Typography fontSize={'1.3rem'} fontWeight={300} lineHeight={'1.5rem'} marginBottom={'1rem'}>
              DebiCheck is a new authorisation process implemented by your bank to reduce fraudulent activity. It gives
              you peace of mind as it ensured all your transactions are 100% verified.{' '}
            </Typography>

            <Link href="https://m.debicheck.co.za/" target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
              <Typography
                fontSize={'1.3rem'}
                fontWeight={300}
                fontStyle={'normal'}
                letterSpacing={'-0.03rem'}
                color={theme.palette.primary.main}
                sx={{ cursor: 'pointer' }}
              >
                Read more
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>

      <Typography
        variant="h3"
        fontSize={{ xs: '2.4rem', sm: '2.6rem' }}
        fontWeight={400}
        lineHeight={{ xs: '3.7rem', sm: '3.5rem' }}
        marginTop={{ xs: '2.4rem', md: '8rem' }}
      >
        Application Details
      </Typography>

      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        marginTop={{ xs: '1.6rem', md: '2.8rem' }}
        sx={{ position: 'relative' }}
      >
        <Box
          sx={{
            display: { xs: 'none', md: 'initial' },
            position: 'absolute',
            top: '2.7rem',
            borderBottom: '1px solid #f5f5f5',
            width: '100%',
          }}
        />
        <div>
          <Typography
            fontSize={'1.5rem'}
            fontWeight={{ xs: 500, md: 600 }}
            lineHeight={'2.1rem'}
            letterSpacing={{ xs: '-0.03rem', md: '-0.05rem' }}
            marginBottom={'1.2rem'}
          >
            Application Date
          </Typography>
          <Typography
            fontSize={{ xs: '1.4rem', md: '1.5rem' }}
            fontWeight={300}
            lineHeight={'2.1rem'}
            letterSpacing={{ xs: '-0.02rem', md: '-0.05rem' }}
          >
            {formatDate(applicationDate)}
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={'1.5rem'}
            fontWeight={{ xs: 500, md: 600 }}
            lineHeight={'2.1rem'}
            letterSpacing={{ xs: '-0.03rem', md: '-0.05rem' }}
            marginBottom={'1.2rem'}
          >
            Loan Term
          </Typography>
          <Typography
            fontSize={{ xs: '1.4rem', md: '1.5rem' }}
            fontWeight={300}
            lineHeight={'2.1rem'}
            letterSpacing={{ xs: '-0.02rem', md: '-0.05rem' }}
          >
            {termInDays !== undefined && termInDays > 0 ? `${termInDays} days` : `${term} months`}
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={'1.5rem'}
            fontWeight={{ xs: 500, md: 600 }}
            lineHeight={'2.1rem'}
            letterSpacing={{ xs: '-0.03rem', md: '-0.05rem' }}
            marginBottom={'1.2rem'}
          >
            Amount
          </Typography>
          <Typography
            fontSize={{ xs: '1.4rem', md: '1.5rem' }}
            fontWeight={300}
            lineHeight={'2.1rem'}
            letterSpacing={{ xs: '-0.02rem', md: '-0.05rem' }}
          >
            R {loanAmount}{' '}
          </Typography>
        </div>
      </Stack>
    </div>
  );
};

export default AccountTimeline;
