/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import React, { useEffect, useState } from 'react';
import {
  EmploymentDetails,
  EmploymentIndustryEnum,
  EmploymentStatusEnum,
  ErrorResponse,
  IEmploymentDetails,
  IGetEmploymentResponse,
  IncomeFrequencyEnum,
  PositionEnum,
  UpdateEmploymentResponse,
} from '../../../../../clients/AccountClient';
import { getEmployedOnDate, getYears, getMonths } from '../../../../../utils/Helpers/Employment';
import { Form, useForm } from '../../../../../components/Form/Form';
import { Box, Container, Grid, Stack, useMediaQuery } from '@mui/material';
import FormInputWrapper from '../../../../../components/Form/FormInputWrapper';
import InputFormController from '../../../../../components/MuiInput/FormControllers/InputFormController';
import FormInputLabel from '../../../../../components/Form/FormInputLabel';
import RepaymentDate from '../../../../../components/Sliders/RepaymentDate';
import { FULL_COLUMN_SIZE } from '../../../../../utils/GridColumnSizeDefinitions';
import { employmentDetailsSchema } from '../../../../../schemas/Schemas';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import useAccountClient from '../../../../../hooks/account/Client';
import { theme } from '../../../../../theme/Theme';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import Loading from '../../../../../components/Loading/Loading';
import TextButton from '../../../../../components/Buttons/TextButton';
import { useTracking } from '../../../../../Tracking/TrackingContext';

interface IChangeSalaryDayLN {
  employmentDetails?: IGetEmploymentResponse;
  onCloseEditing: () => void;
}

const ChangeSalaryDayLN: React.FunctionComponent<IChangeSalaryDayLN> = (props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { displaySnackBar } = useSnackBar();
  const client = useAccountClient();
  const [showRepaymentDatePicker, setShowRepaymentDatePicker] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { TrackError } = useTracking();

  const employmentForm = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    schema: employmentDetailsSchema,
  });

  const salaryDay = employmentForm.watch('salaryDay');

  const getSalaryDayFromLocalStorage = (): string => {
    const applyValues = localStorage.getItem('applyValues');
    if (applyValues) {
      const salaryDay = JSON.parse(applyValues)['salaryDay'];
      return `${salaryDay == 0 ? '' : salaryDay}`;
    }
    return '';
  };

  useEffect(() => {
    employmentForm.setValue('years', getYears(props.employmentDetails?.employment?.employedOn));
    employmentForm.setValue('months', getMonths(props.employmentDetails?.employment?.employedOn));

    employmentForm.setValue(
      'employerName',
      props.employmentDetails?.employment?.employerName ? props.employmentDetails.employment!.employerName! : ''
    );
    employmentForm.setValue(
      'position',
      props.employmentDetails?.employment?.positionEnum
        ? props.employmentDetails.employment!.positionEnum!
        : PositionEnum.Unspecified
    );
    employmentForm.setValue(
      'industry',
      props.employmentDetails?.employment?.industryEnum
        ? props.employmentDetails.employment!.industryEnum!
        : EmploymentIndustryEnum.Unspecified
    );
    employmentForm.setValue(
      'incomeFrequency',
      props.employmentDetails?.employment?.incomeFrequencyEnum
        ? props.employmentDetails.employment!.incomeFrequencyEnum
        : IncomeFrequencyEnum.Unspecified
    );
    employmentForm.setValue(
      'salaryDay',
      // If a day was picked from Sliders, use that date even though the user has a salary date from a previous journey in the backend response
      // if a day was not picked from Sliders (for STLs), use day from previous journey (from database)
      // In case the neither condition is met, the user has to select a new salary day.
      getSalaryDayFromLocalStorage().trim() !== ''
        ? getSalaryDayFromLocalStorage()
        : props.employmentDetails?.employment?.salaryDay
        ? props.employmentDetails?.employment!.salaryDay!.toString()
        : ''
    );
    employmentForm.setValue(
      'employmentStatus',
      props.employmentDetails?.employment?.employmentStatusEnum
        ? props.employmentDetails.employment!.employmentStatusEnum
        : EmploymentStatusEnum.Unspecified
    );
    employmentForm.setValue(
      'grossIncome',
      props.employmentDetails?.employment?.grossIncome
        ? props.employmentDetails.employment!.grossIncome!.toString()
        : ''
    );
    employmentForm.setValue(
      'netIncome',
      props.employmentDetails?.employment?.netIncome ? props.employmentDetails.employment!.netIncome!.toString() : ''
    );
    employmentForm.setValue(
      'workPhone',
      props.employmentDetails?.employment?.workPhone ? props.employmentDetails.employment!.workPhone! : ''
    );

    employmentForm.setValue('university', '');
    employmentForm.setValue('division', '');
  }, [props.employmentDetails, employmentForm]);

  const updateSalaryDayInLocalStorage = (salaryDay: string) => {
    const applyValuesString = localStorage.getItem('applyValues');
    const applyValues = JSON.parse(applyValuesString ?? '');
    if (applyValues) {
      localStorage.setItem('applyValues', JSON.stringify({ ...applyValues, salaryDay }));
    }
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  /* This should be revisited, employedOn is part of IEmploymentDetails but is explicitly ignored. */
  const updateEmployment = async (data: any) => {
    const employ: IEmploymentDetails = {
      ...props.employmentDetails,
      employerName: data.employerName.trim(),
      employmentStatusEnum: data.employmentStatus,
      workPhone: data.workPhone,
      industryEnum: data.industry,
      positionEnum: data.position,
      incomeFrequencyEnum: data.incomeFrequency,
      salaryDay: data.salaryDay,
      grossIncome: data.grossIncome,
      netIncome: data.netIncome,
      employedOn:
        (data.years && data.months) === ''
          ? new Date('1/1/0001')
          : new Date(getEmployedOnDate(data.years, data.months)),
    };

    try {
      const employment: UpdateEmploymentResponse = await client.updateEmployment(new EmploymentDetails(employ));

      if (employment.employmentDetailsUpdated) {
        console.log('Successfully updated employment details');
        displaySnackBar('Employment Details saved', 'success');
        updateSalaryDayInLocalStorage(data.salaryDay);
        setSubmitLoading(false);
        props.onCloseEditing();
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error instanceof ErrorResponse && error.errorCode === 400) {
        TrackError(error, error.message);
        displaySnackBar(error.message ?? "An validation error has occurred", 'error');
      } else {
        displaySnackBar('Oops, an error has occurred please try again', 'error');
      }
    }
  };

  const onFormSubmit = async (data: any) => {
    setSubmitLoading(true);
    await updateEmployment(data);
  };

  const onSalaryDayPick = (day: number) => {
    employmentForm.setValue('salaryDay', day.toString());
    employmentForm.trigger('salaryDay');
    setShowRepaymentDatePicker(false);
  };

  return (
    <Form form={employmentForm} onSubmit={onFormSubmit}>
      <Grid container style={{ position: 'relative' }} marginBottom={'2.2rem'}>
        {showRepaymentDatePicker && (
          <Container
            style={{
              position: 'absolute',
              bottom: '-70px',
              left: isMobile ? '10px' : '385px',
            }}
          >
            <RepaymentDate
              SetShowRepaymentDatePicker={setShowRepaymentDatePicker}
              displayRepaymentDate={showRepaymentDatePicker}
              onSalaryDayPick={onSalaryDayPick}
            />
          </Container>
        )}
        <FormInputLabel>Salary Day</FormInputLabel>
        <FormInputWrapper>
          <Box onClick={() => setShowRepaymentDatePicker(!showRepaymentDatePicker)}>
            <InputFormController
              type="number"
              name="salaryDay"
              label="Salary Day"
              salaryDay={parseInt(salaryDay)}
              placeholder="Salary Day"
              register={employmentForm.register}
              control={employmentForm.control}
            />
          </Box>
        </FormInputWrapper>
        {!submitLoading && (
          <>
            <Grid item xs={FULL_COLUMN_SIZE}>
              <Stack
                flexGrow={1}
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                justifyContent={{ xs: 'center', sm: 'space-between' }}
                marginTop={'1rem'}
              >
                <TextButton type="reset" removePadding onClick={props.onCloseEditing}>
                  Cancel
                </TextButton>
                <PrimaryButton type="submit">Update</PrimaryButton>
              </Stack>
            </Grid>
          </>
        )}
        {submitLoading && (
          <Grid item xs={FULL_COLUMN_SIZE}>
            <Stack alignItems={{ xs: 'center', sm: 'flex-end' }} marginTop={'1rem'}>
              <Box textAlign={'center'} width={{ xs: '100%', sm: '25rem' }}>
                <Loading />
              </Box>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

export default ChangeSalaryDayLN;
