import React, { useCallback, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { mapOzowPaymentResponseToPaymentStatus } from "../utils/Helpers/PaymentStatusMappings";
import PaymentStatusDialog from "../components/PaymentStatusDialog/PaymentStatusDialog";
import { useSearchParams } from "react-router-dom";
import useLoanClient from "../hooks/loan/Client";
import { OzowPaymentStatusRequest } from "../clients/LoanClient";

export const PaymentDialogContext = React.createContext({
    /* eslint-disable  @typescript-eslint/no-unused-vars*/
});

export const usePaymentDialog = () => {
    return useContext(PaymentDialogContext);
};

interface paymentDialogProviderProps {
    children: React.ReactNode
}

const PaymentDialogProvider = (props: paymentDialogProviderProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const ozowResponse = searchParams.get("ozowResponse");
    const paymentStatus = ozowResponse ? mapOzowPaymentResponseToPaymentStatus(ozowResponse) : undefined;
    const loanClient = useLoanClient();

    const logOzowStatus = useCallback(async () => {
        if (ozowResponse) {
            const openloanDetailsResponse = await loanClient.getOpenLoanDetails();
            const loanId = openloanDetailsResponse.loanData?.loanId;
            const request = new OzowPaymentStatusRequest({ loanId, status: ozowResponse });
            await loanClient.logPaymentResult(request);
        }
    }, [loanClient, ozowResponse]);

    useEffect(() => {
        logOzowStatus();
    }, [logOzowStatus, ozowResponse]);

    const removeParamsFromUrl = () => {
        setSearchParams(undefined);
    };

    const content = (
        <>
            {!!paymentStatus &&
                <PaymentStatusDialog status={paymentStatus} onButtonClick={removeParamsFromUrl} />
            }
        </>
    );

    return (
        <>
            <PaymentDialogContext.Provider value={{}}>
                {props.children}
            </PaymentDialogContext.Provider>
            {ReactDOM.createPortal(
                content,
                /* eslint-disable  @typescript-eslint/no-non-null-assertion*/
                document.getElementById("payment-dialog-root")!
            )}
        </>
    );
};

export default PaymentDialogProvider;
