/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import styles from './LoanAgreement.module.scss';
import { Button, Container } from '@mui/material';
import {
  DeclineApplicationRequest,
  IDeclineApplicationRequest,
  IOpenLoanDetailsResponse,
} from '../../../../clients/LoanClient';
import { useNavigate } from 'react-router-dom';
import useLoanClient from '../../../../hooks/loan/Client';
import { useSnackBar } from '../../../../contexts/SnackBarContext';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { navigateToTermsAndConditions } from '../../../../utils/Helpers/NavigationHelper';
import InputDisplaysWithFeeBreakdown from '../FeesBreakdown/Helpers/InputDisplaysWithFeeBreakdown';
import { formatMoney, formatRepaymentDay } from '../../../../utils/Helpers/SliderHelperFunctions';
import { differenceInCalendarDays } from 'date-fns';
import { formatDateWithDefault } from '../../../../utils/Helpers/DateHelpers';
import { useTracking } from '../../../../Tracking/TrackingContext';
import { RouterRoutes } from '../../../../utils/RouterRoutes';
import Loading from '../../../../components/Loading/Loading';
import FeesBreakdown from '../FeesBreakdown/FeesBreakdown';

type LoanAgreementProps = {
  openLoanResponse: IOpenLoanDetailsResponse;
  setOpenLoanDetailsResponse: React.Dispatch<React.SetStateAction<IOpenLoanDetailsResponse | null>>;
};
const LoanAgreement: React.FunctionComponent<LoanAgreementProps> = ({
  openLoanResponse,
  setOpenLoanDetailsResponse,
}) => {
  const {
    loanAmount,
    initiationFee,
    insuranceFee,
    serviceFee,
    totalServiceFees,
    totalRepayable,
    promiseDate,
    collectionDates,
    term,
    termInDays,
    interestAmount,
    salaryDay,
    deathBenefit,
  } = openLoanResponse.quotationData || {};
  const { loanId } = openLoanResponse.loanData || {};
  const [showFeesBreakdown, setShowFeesBreakdown] = useState<boolean>(false);
  const navigate = useNavigate();
  const loanClient = useLoanClient();
  const { displaySnackBar } = useSnackBar();
  const isInstallmentLoan = collectionDates?.length! > 0;
  const firstCollectionDate = isInstallmentLoan ? collectionDates![0] : promiseDate;
  const { isExistingCustomer } = useTracking();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const redirectTermsAndConditionsPage = () => {
    navigateToTermsAndConditions(navigate, loanId ?? '', isExistingCustomer);
  };

  const handleNoThanks = async () => {
    setSubmitLoading(true);
    const dto: IDeclineApplicationRequest = {
      loanId: openLoanResponse.loanData?.loanId,
    };
    const request = new DeclineApplicationRequest(dto);
    loanClient
      .declineApplication(request)
      .then(() => {
        setOpenLoanDetailsResponse((prevState) => ({
          ...prevState,
          hasOpenLoan: false,
          openLoanStatus: undefined,
        }));
        //removes the page key from url without re-render
        window.history.replaceState(null, '', `/${RouterRoutes.myLoan}`);
        setSubmitLoading(false);
      })
      .catch((error) => {
        console.error(error);
        displaySnackBar('Oops! An error occurred. Please try again.', 'error');
        setSubmitLoading(false);
      });
  };

  const daysUntilPaymentDue = (): number => {
    if (!firstCollectionDate) return 0;

    return differenceInCalendarDays(firstCollectionDate, new Date());
  };

  const calculateIlOrSTL = () => {
    if (isInstallmentLoan) {
      return `${term} Months`;
    } else {
      return `${termInDays} Days`;
    }
  };

  const calculateInterestAndFees = () => {
    const totalFees = formatMoney(totalServiceFees! + interestAmount!);
    return totalFees;
  };

  const feesBreakdownHandler = () => {
    setShowFeesBreakdown(!showFeesBreakdown);
  };

  return (
    <div className={styles['loanAgreement-container']}>
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={400}
        lineHeight={'3.2rem'}
        letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
        paddingBottom={'2rem'}
      >
        You have been approved.
      </Typography>
      <Typography className={styles['loanAgreement-subtitle']}>
        The interest and fees for your loan amount are:
      </Typography>
      <div className={styles['inputs-wrapper']}>
        <div className={styles['inputs-block']}>
          <InputDisplaysWithFeeBreakdown type="text" value={`R ${formatMoney(loanAmount ?? 0)}`} />
          <Typography className={styles['loanAgreement-labels']}>Amount</Typography>
        </div>
        <div className={styles['inputs-block']}>
          <div onClick={feesBreakdownHandler} className={styles['inputDisplayOnly-wrapper']}>
            <InputDisplaysWithFeeBreakdown type="text" value={`R ${calculateInterestAndFees()}`} />
          </div>
          <Typography className={styles['loanAgreement-labels']}>Interest & fees</Typography>
        </div>
        <div className={styles['inputs-block']}>
          <InputDisplaysWithFeeBreakdown type="text" value={`R ${formatMoney((totalRepayable ?? 0) + (deathBenefit?.contractValue ?? 0))}`} />
          <Typography className={styles['loanAgreement-labels']}>Total to repay</Typography>
        </div>
        <div className={styles['inputs-block-show']}>
          <InputDisplaysWithFeeBreakdown type="text" value={calculateIlOrSTL()} />
          <Typography className={styles['loanAgreement-labels']}>Term</Typography>
        </div>
        <div className={styles['text-block']}>
          <span className={styles['divider']}></span>
          {isInstallmentLoan ? (
            <Typography className={styles['loanAgreement-labels-text']}>
              Your instalments will be due on the{' '}
              <strong className={styles['loanAgreement-labels-text-bold']}>
                {formatRepaymentDay(salaryDay ?? 25)}
              </strong>{' '}
              of every month
            </Typography>
          ) : (
            <Typography className={styles['loanAgreement-labels-text']}>
              Your instalment is due in
              <strong
                className={styles['loanAgreement-labels-text-bold']}
              >{` ${daysUntilPaymentDue()} days (${formatDateWithDefault(
                firstCollectionDate,
                'do MMMM yyyy'
              )})`}</strong>
            </Typography>
          )}
        </div>
      </div>
      {submitLoading ? (
        <Loading />
      ) : (
        <>
          <Typography className={styles['loanAgreement-finish']}>
            To finish this application, please click ‘Continue’.
          </Typography>
          <Container className={styles['buttons-container']}>
            <Button onClick={handleNoThanks} className={styles['loanAgreement-noThanks']}>
              No thanks
            </Button>
            <PrimaryButton type="submit" onClick={redirectTermsAndConditionsPage}>
              Continue
            </PrimaryButton>
          </Container>
        </>
      )}
      {showFeesBreakdown ? (
        <FeesBreakdown
          initiationFee={initiationFee}
          serviceFee={serviceFee}
          totalInterest={interestAmount}
          insuranceFee={insuranceFee}
          feesBreakdownHandler={feesBreakdownHandler}
          totalRepayable={(totalRepayable ?? 0) + (deathBenefit?.contractValue ?? 0)}
          deathBenefitContractValue={deathBenefit?.contractValue ?? 0}
        />
      ) : null}
    </div>
  );
};

export default LoanAgreement;
