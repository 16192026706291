export enum PaymentStatus {
    Cancelled = "cancelled",
    Successful = "success",
    Failed = "failed",
}

enum OzowPaymentResponse {
    Cancelled = "cancelledResponse",
    Successful = "successResponse",
    Failed = "errorResponse",
}

//This is being mapped for reusablity of payment status dialog with other payment options in the future
export const mapOzowPaymentResponseToPaymentStatus = (paymentResponse: string): PaymentStatus | undefined => {
    if (paymentResponse === OzowPaymentResponse.Successful) return PaymentStatus.Successful;
    if (paymentResponse === OzowPaymentResponse.Cancelled) return PaymentStatus.Cancelled;
    if (paymentResponse === OzowPaymentResponse.Failed) return PaymentStatus.Failed;
    return undefined;
}