// Update the RouterRoutesEnum Enum below when you update these routes
export class RouterRoutes {
  static home = '/';
  static login = 'login';
  static newJourney = 'apply-new';
  static existingJourney = 'apply';
  static recoverPassword = 'recover-password';
  static LoginDialog = 'login-dialog';
  static myAccount = 'my-account';
  static accountSummary = 'summary';
  static profile = 'profile';
  static employment = 'employment';
  static bank = 'bank';
  static loanDocuments = 'loan-documents';
  static statementPrint = 'statement-print';
  static paidUpLetterPrint = 'paid-up-letter-print';
  static legalDocumentPrint = 'legal-document-print';
  static confirmChangeEmail = 'confirm-new-email-address';
  static confirmPasswordReset = 'confirm-password-reset';
  static documentsUpload = 'documents';
  static termsAndConditions = 'terms-and-conditions';
  static counteroffer = 'counteroffer';
  static repay = 'repay';
  static eft = 'eft';
  static instantEft = 'instant-eft';
  static easyPay = 'easypay';
  static emailResend = 'recover-password-resend';
  static myLoan = 'my-loan';
  static myDetails = 'my-details';
  static limiter = 'limiter';
  static register = 'register';
  static confirmIdNumber = 'confirm-id-number';
  static confirmOTP = 'confirm-otp';
  static MissingCellNumber = 'missing-cell-number';
  static leadAccept = 'lead-accept';
  static settlementLetterPrint = 'settlement-letter-print'
}

export enum L0JourneyRoutes {
  otp = 'contact-details',
  details = 'details',
  address = 'address',
  employment = 'employment',
  affordability = 'affordability',
  bank = 'bank',
  processing = 'processing',
  creditLifePlus = 'death-benefit',
  creditLifeLoanRepaymentDetails = 'loan-repayment-details',
  creditLifePlusBeneficiary = 'death-benefit-beneficiary',
}

export enum LNJourneyRoutes {
  otp = 'contact-details',
  details = 'details',
  address = 'address',
  employment = 'employment',
  affordability = 'affordability',
  bank = 'bank',
  processing = 'processing',
  creditLifePlus = 'death-benefit',
  creditLifeLoanRepaymentDetails = 'loan-repayment-details',
  creditLifePlusBeneficiary = 'death-benefit-beneficiary',
}

export enum LNJourneyShortenedRoutes {
  confirmDetails = 'confirm-details',
  updateDetails = 'update-customer-details',
  updateAffordability = 'update-finance',
  processing = 'processing',
  creditLifePlus = 'death-benefit',
  creditLifeLoanRepaymentDetails = 'loan-repayment-details',
  creditLifePlusBeneficiary = 'death-benefit-beneficiary',
}

export enum PostProcessingRoutes {
  declined = 'declined',
  counterOffer = 'counter-offer',
  documentUpload = 'document-upload',
  agreement = 'agreement',
  counterOfferDecline = 'counter-offer-decline',
  counterOfferProcessing = 'counter-offer-processing',
  IvOptions = 'iv-options',
  OpenBankingProcessing = 'open-banking-processing',
  OpenBankingFailure = 'open-banking-failure',
}

// User activity is not to be detected on these pages
export const whitelist = ['/login', '/confirm-id-number', '/confirm-otp', '/recover-password', '/confirm-password-reset', '/confirm-new-email-address'];

// This enum is used to list pages where user activity should be recorded
// This is so that in case a user goes to a route that doesn't exist, activity will not be recorded on the PageNotFound component
// Routes Login, & Register should not be part of this enum, as user activity is not recorded on them.
export enum RouterRoutesEnum {
  Home = '/',
  MyAccount = '/my-account', // this is also /summary
  Profile = '/my-account/profile',
  Employment = '/my-account/employment',
  Bank = '/my-account/bank',
  Statements = '/my-account/statements',
  Documents = '/documents',
  TermAndConditions = '/terms-and-conditions',
  Wizard = '/wizard',
  Counteroffer = '/counteroffer',
  Repay = '/my-account/repay',
  Eft = '/my-account/repay/eft',
  InstantEft = '/my-account/repay/instant-eft',
  EasyPay = '/my-account/repay/easypay',
  newJourney = 'apply-new',
  existingJourney = 'apply',
  IvOptions = 'iv-options',
  OpenBankingProcessing = 'open-banking-processing',
  OpenBankingFailure = 'open-banking-failure',
}
