/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import styles from './InstantEft.module.scss';
import lightbulb from '../../../../../assets/img/svg/lightbulb-big.svg';
import { IOpenLoanDetailsResponse } from '../../../../../clients/LoanClient';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import TextButton from '../../../../../components/Buttons/TextButton';
import FormInputLabel from '../../../../../components/Form/FormInputLabel';
import FormInputWrapper from '../../../../../components/Form/FormInputWrapper';
import InputFormController from '../../../../../components/MuiInput/FormControllers/InputFormController';
import { Form, useForm } from '../../../../../components/Form/Form';
import { repayInstantEftSchema } from '../../../../../schemas/Schemas';
import useLoanClient from '../../../../../hooks/loan/Client';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import Loading from '../../../../../components/Loading/Loading';
import { formatMoney } from '../../../../../utils/Helpers/SliderHelperFunctions';
import { formatDateWithDefault } from '../../../../../utils/Helpers/DateHelpers';

interface InstantEftProps {
  openLoanResponse: IOpenLoanDetailsResponse;
}

const InstantEft: React.FunctionComponent<InstantEftProps> = ({ openLoanResponse }: InstantEftProps) => {
  const { currentBalance, collectionDate, collectionInstalment } = openLoanResponse.loanData || {};
  const { loanId } = openLoanResponse.loanData || {};
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const client = useLoanClient();
  const { displaySnackBar } = useSnackBar();
  const [isLoading, setLoading] = useState(false);

  const form = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    schema: repayInstantEftSchema(currentBalance ?? 0),
  });

  useEffect(() => {
    form.setValue('repayAmount', currentBalance?.toString() ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateBack = () => {
    navigate(-1);
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any*/
  const handlePaymentSession = async (data: any) => {
    try {
      setLoading(true);
      const ozowResponse = await client.getPaymentSessionUrl(loanId ?? '', data.repayAmount);
      if (ozowResponse.sessionUrl) {
        window.location.replace(ozowResponse.sessionUrl);
      } else {
        displaySnackBar('We are currently experiencing technical difficulties. Please try again later', 'error');
      }
    } catch (error) {
      displaySnackBar('We are currently experiencing technical difficulties. Please try again later', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles['instant-eft-container']}>
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={400}
        lineHeight={'3.2rem'}
        letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
      >
        Pay using Instant EFT Ozow
      </Typography>
      <div className={styles['instant-eft-hint-container']}>
        <img src={lightbulb} aria-label="Lightbulb Icon" className={styles['instant-eft-hint-icon']} />
        <div className={styles['instant-eft-hint-text']}>
          Payments made within 5 days of your next instalment may not be processed in time. Once payment is made, this
          can take 48 working hours to reflect on your loan account.
        </div>
      </div>

      <div className={styles['instant-eft-table']}>
        <div>New debit collection date</div>
        <div>{formatDateWithDefault(collectionDate, 'do MMM yyyy')}</div>
      </div>
      <div className={styles['instant-eft-table']}>
        <div>Your next debit amount</div>
        <div>R {formatMoney(collectionInstalment ?? 0)}</div>
      </div>

      <div className={styles['instant-eft-table-bold']}>
        <div>Current Balance</div>
        <div>R {formatMoney(currentBalance ?? 0)}</div>
      </div>

      <Form form={form} onSubmit={(data) => handlePaymentSession(data)}>
        <Grid container paddingTop={'2rem'}>
          <FormInputLabel>How much do you want to repay?</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              currencyField
              name="repayAmount"
              label="repayAmount"
              placeholder="Amount"
              register={form.register}
              control={form.control}
            />
          </FormInputWrapper>
        </Grid>
        {isLoading ? (
          <Loading />
        ) : (
          <Stack
            direction={isMobile ? 'column' : 'row-reverse'}
            justify-content={isMobile ? 'center' : 'right'}
            padding="3.4rem 0rem 2rem 0rem"
          >
            <PrimaryButton type="submit">Confirm</PrimaryButton>
            <TextButton onClick={navigateBack}>Back</TextButton>
          </Stack>
        )}
      </Form>
    </div>
  );
};

export default InstantEft;
