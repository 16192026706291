import { OptionsWithTZ, formatInTimeZone } from "date-fns-tz";
import { format } from "date-fns";

export enum MonthEnum {
    January = "January",
    February = "February",
    March = "March",
    April = "April",
    May = "May",
    June = "June",
    July = "July",
    August = "August",
    September = "September",
    October = "October",
    November = "November",
    December = "December"
}

/**
 * Creates a formatted UTC time zone equivalent date.
 *
 * @param {Date | number} date The date to format.
 * @param {string} formatStr [ https://date-fns.org/v3.6.0/docs/format ] (optional) The format string to use for formatting. Defaults to "dd MMM yyyy".
 * @param {OptionsWithTZ} [options] [ https://github.com/marnusw/date-fns-tz/blob/master/typings.d.ts ] (optional) Options related to time zones.
 * @returns {string} The formatted date string in UTC.
 */
export function utcFormattedDate(
    date: number | Date,
    formatStr = 'dd MMM yyyy',
    options?: OptionsWithTZ | undefined): string {
    return formatInTimeZone(date, 'UTC', formatStr, options);
}

/**
 * Checks if the specified day of the week is today, based on UTC time zone.
 *
 * @param {string} targetDay Specific day of the week.
 * @returns {boolean}
 */
export function isCurrentDayUTC(targetDay: string): boolean {
    return formatInTimeZone(new Date(), 'UTC', 'EEEE') === targetDay;
}

/**
 * Checks if the specified date is the provided day of the week, based on UTC time zone.
 *
 * @param {number | Date} date Date
 * @param {string} targetDay Specific day of the week.
 * @returns {boolean}
 */
export function isSpecifiedDayOfTheWeek(date: number | Date, targetDay: string): boolean {
    return formatInTimeZone(date, 'UTC', 'EEEE') === targetDay;
}

/**
 * Checks if the specified date is in the provided month, based on the UTC time zone.
 *
 * @param {number | Date} date Date
 * @param {MonthEnum} targetMonth Specific month (e.g., "January", "February").
 * @returns {boolean}
 */
export function isSpecifiedMonth(date: number | Date, targetMonth: MonthEnum): boolean {
    return formatInTimeZone(date, 'UTC', 'MMMM') === targetMonth;
}

/**
 * Creates a formatted date.  
 *
 * @param {Date | number} date The date to format. Defaults to today.
 * @param {string} formatStr [ https://date-fns.org/v3.6.0/docs/format ] (optional) The format string to use for formatting. Defaults to "dd MMM yyyy".
 * @param {OptionsWithTZ} [options] [ https://github.com/marnusw/date-fns-tz/blob/master/typings.d.ts ] (optional) Options related to time zones.
 * @returns {string} The formatted date string.
 */
export function formatDateWithDefault(
    date: number | Date | undefined,
    formatStr = 'dd MMM yyyy',
    options?: OptionsWithTZ | undefined): string {
    return format(date ?? new Date(), formatStr, options)
}

/**
 * Calculates the persons age from their ID number. Range is between 0 - 99, we can't use id number to calcalate age for people older than 99.
 *
 * @param {string?} idNumber
 * @param {string?} staticDate Note: this is only used for testing
 * @returns {number | undefined}
 */
export function getAgeFromID(idNumber?: string, staticDate?: Date): number | undefined {
    try {
        if (!idNumber) return undefined;

        // Extract the birthdate parts from the ID number
        const yearPart = idNumber.slice(0, 2);
        const monthPart = idNumber.slice(2, 4);
        const dayPart = idNumber.slice(4, 6);

        // Convert parts to integers
        let year = parseInt(yearPart, 10);
        const month = parseInt(monthPart, 10) - 1; // Months are zero-indexed in JavaScript Date
        const day = parseInt(dayPart, 10);

        let today = new Date();

        if (staticDate != undefined) today = staticDate;

        // Determine the full year
        const currentYear = today.getFullYear();
        const currentCentury = Math.floor(currentYear / 100) * 100;
        const birthYear = year + currentCentury;

        // Adjust for those born in 1900s
        if (birthYear > currentYear) {
            year = year + currentCentury - 100;
        } else {
            year = year + currentCentury;
        }

        // Create the birthdate
        const birthDate = new Date(year, month, day);

        // Calculate age
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const isBeforeBirthday = today.getDate() < birthDate.getDate();
        if (monthDiff < 0 || (monthDiff === 0 && isBeforeBirthday)) {
            age--;
        }

        return age;
    } catch (error) {
        console.log(error);
    }
}