/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import styles from './CounterOffer.module.scss';
import { Button, Container } from '@mui/material';
import {
  AcceptOfferRequest,
  IAcceptOfferRequest,
  IOpenLoanDetailsResponse,
  IRejectOfferRequest,
  RejectOfferRequest,
} from '../../../../clients/LoanClient';

import FeesBreakdown from '../FeesBreakdown/FeesBreakdown';
import useLoanClient from '../../../../hooks/loan/Client';
import { useSnackBar } from '../../../../contexts/SnackBarContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouterRoutes } from '../../../../utils/RouterRoutes';
import Loading from '../../../../components/Loading/Loading';
import {
  navigateToCounterOfferDecline,
  navigateToCounterOfferProcessing,
} from '../../../../utils/Helpers/NavigationHelper';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { formatMoney, formatRepaymentDay, wait } from '../../../../utils/Helpers/SliderHelperFunctions';
import InputDisplaysWithFeeBreakdown from '../FeesBreakdown/Helpers/InputDisplaysWithFeeBreakdown';
import { useTracking } from '../../../../Tracking/TrackingContext';

type CounterOfferProps = {
  openLoanResponse?: IOpenLoanDetailsResponse;
  setOpenLoanDetailsResponse?: React.Dispatch<React.SetStateAction<IOpenLoanDetailsResponse | null>>;
};

const CounterOffer: React.FunctionComponent<CounterOfferProps> = ({ openLoanResponse }) => {
  const [showFeesBreakdown, setShowFeesBreakdown] = useState(false);
  const feesBreakdownHandler = () => {
    setShowFeesBreakdown(!showFeesBreakdown);
  };
  const loanClient = useLoanClient();
  const { displaySnackBar } = useSnackBar();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { TrackQuotation, isExistingCustomer } = useTracking();
  const [openLoanDetails, setOpenLoanDetails] = useState<IOpenLoanDetailsResponse | null>(null);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const {
    loanAmount,
    totalServiceFees,
    totalRepayable,
    initiationFee,
    serviceFee,
    term,
    termInDays,
    salaryDay,
    promiseDate,
    interestAmount,
    insuranceFee,
    deathBenefit,
  } = openLoanDetails?.quotationData || {};
  const location = useLocation();
  const isInMyLoan = location.pathname.includes(RouterRoutes.myLoan);

  const getOpenLoanDetailsWithRetry = async (currentRetryAttempt = 1, maxRetries = 5) => {
    try {
      setIsFetchingData(true);
      const response = await loanClient.getOpenLoanDetails();
      setOpenLoanDetails(response);
      setIsFetchingData(false);
    } catch (error) {
      if (currentRetryAttempt < maxRetries) {
        await wait(500);
        await getOpenLoanDetailsWithRetry(currentRetryAttempt + 1, maxRetries);
      } else {
        navigate(`/${RouterRoutes.myLoan}`);
      }
    }
  };

  useEffect(() => {
    if (openLoanResponse) {
      setOpenLoanDetails(openLoanResponse);
    } else {
      getOpenLoanDetailsWithRetry();
    }
    // eslint-disable-next-line
  }, []);

  const handleAcceptCounteroffer = async () => {
    setSubmitLoading(true);

    const loanId = openLoanDetails?.loanData?.loanId;

    const dto: IAcceptOfferRequest = {
      loanId: loanId,
    };

    const request = new AcceptOfferRequest(dto);

    loanClient
      .acceptCounteroffer(request)
      .then(() => {
        TrackQuotation(openLoanDetails?.quotationData, openLoanDetails?.loanData?.loanId ?? '');
        navigateToCounterOfferProcessing(navigate, loanId ?? '', isExistingCustomer);
      })
      .catch((error) => {
        console.error(error);
        displaySnackBar('Oops! An error occurred. Please try again.', 'error');
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleNoThanks = async () => {
    setSubmitLoading(true);

    const loanId = openLoanDetails?.loanData?.loanId ?? '';

    const dto: IRejectOfferRequest = {
      loanId: loanId,
    };
    const request = new RejectOfferRequest(dto);

    loanClient
      .rejectCounteroffer(request)
      .then(() => {
        navigateToCounterOfferDecline(navigate, loanId, isExistingCustomer, true);
      })
      .catch((error) => {
        console.error(error);
        displaySnackBar('Oops! An error occurred. Please try again.', 'error');
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const getStlDueDateText = (promiseDate: Date | undefined): JSX.Element => {
    if (promiseDate === undefined) return <></>;

    const currentDate = new Date();

    // Calculate the difference in days
    const diffTime = Math.abs(promiseDate.getTime() - currentDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // Format the promise date
    const formattedDate = `${promiseDate.getDate()} ${promiseDate.toLocaleString('default', {
      month: 'long',
    })} ${promiseDate.getFullYear()}`;

    return (
      <>
        Your installment is due in <strong>{diffDays}</strong> days (<strong>{formattedDate}</strong>)
      </>
    );
  };

  let interestAndFees = 0;
  if (totalServiceFees !== undefined && interestAmount != undefined) {
    interestAndFees = totalServiceFees + interestAmount;
  }

  if (isFetchingData) return <Loading text="Please wait while we retrieve your account details" />;

  return (
    <>
      <Container
        maxWidth="lg"
        disableGutters={isInMyLoan}
        sx={isInMyLoan ? { padding: '0 0 5rem 0' } : { padding: '2rem 2rem 5rem 2rem' }}
      >
        <div className={styles['counteroffer-container']}>
          <Typography
            variant="h1"
            fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
            fontWeight={400}
            lineHeight={{ xs: '3rem', sm: '3.5rem' }}
            paddingBottom={{ xs: '2rem', sm: '1.6rem' }}
          >
            We have adjusted your loan offer
          </Typography>

          <Typography className={styles['counteroffer-subtitle']}>
            While <strong>R&nbsp;{openLoanDetails?.originalLoanAmount}</strong> isn&apos;t a match for your current financial
            standing, we have crafted an offer aligned to your repayment affordability to help you reach your goals.
          </Typography>

          <div className={styles['inputs-wrapper']}>
            <div className={styles['inputs-block']}>
              <InputDisplaysWithFeeBreakdown type="text" value={`R ${formatMoney(loanAmount ?? 0)}`} />
              <Typography className={styles['counteroffer-labels']}>Amount</Typography>
            </div>

            <div className={styles['inputs-block']}>
              <div className={styles['inputDisplayOnly-wrapper']} onClick={() => setShowFeesBreakdown(true)}>
                <InputDisplaysWithFeeBreakdown type="text" value={`R ${formatMoney(interestAndFees)}`} />
              </div>
              <Typography className={styles['counteroffer-labels']}>Interest & fees</Typography>
            </div>
            <div className={styles['inputs-block']}>
              <InputDisplaysWithFeeBreakdown type="text" value={`R ${formatMoney((totalRepayable ?? 0) + (deathBenefit?.contractValue ?? 0))}`} />
              <Typography className={styles['counteroffer-labels']}>Total to repay</Typography>
            </div>
            <div className={styles['inputs-block']}>
              <InputDisplaysWithFeeBreakdown
                type="text"
                value={termInDays === 0 ? `${term} months` : `${termInDays} days`}
              />
              <Typography className={styles['counteroffer-labels']}>Term</Typography>
            </div>

            <div className={styles['text-block']}>
              <span className={styles['divider']}></span>
              <Typography className={styles['counteroffer-labels-text']}>
                {term !== 0 ? (
                  <>
                    Your instalments will be due on the <strong>{formatRepaymentDay(salaryDay ?? 25)}</strong> of every
                    month
                  </>
                ) : (
                  getStlDueDateText(promiseDate)
                )}
              </Typography>
            </div>
          </div>

          {submitLoading ? (
            <Loading />
          ) : (
            <Container className={styles['buttons-container']}>
              <Button onClick={handleNoThanks} className={styles['counteroffer-noThanks']}>
                No thanks
              </Button>
              <PrimaryButton type="submit" onClick={handleAcceptCounteroffer}>
                Accept
              </PrimaryButton>
            </Container>
          )}

          {showFeesBreakdown ? (
            <FeesBreakdown
              initiationFee={initiationFee}
              serviceFee={serviceFee}
              totalInterest={interestAmount}
              insuranceFee={insuranceFee}
              feesBreakdownHandler={feesBreakdownHandler}
              totalRepayable={(totalRepayable ?? 0) + (deathBenefit?.contractValue ?? 0)}
              deathBenefitContractValue={deathBenefit?.contractValue ?? 0}
            />
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default CounterOffer;
