import { z } from "zod";
import RegularExpressions from "../components/Validation/RegularExpressions";

export const firstnameContraints = z
  .string()
  .min(2, { message: "Please enter a valid first name" })
  .max(75, { message: "Please enter a valid first name" });

export const lastnameContraints = z
  .string()
  .min(2, { message: "Please enter a valid last name" })
  .max(75, { message: "Please enter a valid last name" });

export const emailContraints = z
  .string()
  .email({ message: "Please enter a valid email address." });

export const passwordTests = [
  { regex: new RegExp(RegularExpressions.atleastEightCharacters), message: '8 characters' },
  { regex: new RegExp(RegularExpressions.atleastOneNumber), message: '1 number' },
  { regex: new RegExp(RegularExpressions.specialChar), message: '1 special character ( @#$!*>? )' },
  { regex: new RegExp(RegularExpressions.atleastOneUppercaseLetter), message: '1 upper case letter' },
  { regex: new RegExp(RegularExpressions.atleastOneLowercaseLetter), message: '1 lower case letter' },
];

export const passwordConstraints = z.string()
  .nonempty({ message: "This field is required" })
  .trim()
  .superRefine((password, ctx) => {
    const failedTests = passwordTests.filter(test => !test.regex.test(password));
    const message = `${failedTests.map(test => test.message).join(', ')}`;
    if (failedTests.length > 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `${message}`,
      });
    }
  });

export const mobileNumberConstraints = z
  .string()
  .regex(new RegExp(RegularExpressions.mobilePhone), {
    message: "Enter a valid 10 digit number starting with 0",
  });

export const otpContraints = z
  .string()
  .min(4, { message: "OTP cannot be less than 4 digits" })
  .max(4, { message: "OTP cannot be greater than 4 digits" });

export const incomeConstraints = z
  .string()
  .trim()
  .nonempty("This field is required")
  .max(6, "This value is too large")
  .regex(new RegExp(RegularExpressions.numericOnly), {
    message: "This field is not a valid number",
  })
  .refine(value => parseInt(value) >= 1, {
    message: "This value is too small"
  });
