import React from 'react';
import styles from './ActiveLoan.module.scss';
import { formatMoney } from '../../../../utils/Helpers/SliderHelperFunctions';
import { Stack, Typography } from '@mui/material';
import InfoIcon from '../../../../assets/img/info-icon.png';

interface LoanDetailsProps {
  loanAmount: number;
  totalInterestAndFees: number;
  isInstalmentLoan: boolean;
  term: number;
  onShowFeesBreakdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoanDetails: React.FunctionComponent<LoanDetailsProps> = (props: LoanDetailsProps) => {
  const { loanAmount, totalInterestAndFees, isInstalmentLoan, term, onShowFeesBreakdown } = props;

  const calculateIlOrSTL = () => {
    if (isInstalmentLoan) {
      return <>{term} Months</>;
    } else {
      return <>{term} Days</>;
    }
  };

  return (
    <>
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={400}
        lineHeight={'3.2rem'}
        letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
        marginBottom={'2rem'}
      >
        Loan application details
      </Typography>
      <div className={`${styles['active-loan-table']} ${styles['no-border']}`}>
        <div>Loan Amount</div>
        <div>R {formatMoney(loanAmount)}</div>
      </div>
      <div className={styles['active-loan-table']}>
        <Stack direction={'row'} alignItems={'center'}>
          Total fees & interest
          <img
            onClick={() => onShowFeesBreakdown(true)}
            src={InfoIcon}
            width={'15px'}
            height={'15px'}
            style={{ marginLeft: '0.8rem', cursor: 'pointer' }}
            alt="infoIcon"
          />
        </Stack>
        <div>R {formatMoney(totalInterestAndFees)}</div>
      </div>
      <div className={`${styles['active-loan-table']} ${styles['border-bottom']}`}>
        <div> Term</div>
        <div>{calculateIlOrSTL()}</div>
      </div>
    </>
  );
};

export default LoanDetails;
